(<template>
  <page-data-section :title="sectionTitle"
                     :progress="progressActive"
                     class="ent-settings">
    <div class="ent-settings__content-wrapper">
      <div class="ent-settings__filtration">
        <sk-select v-if="hasDepartments"
                   :items-list="departmentsFilterList"
                   :default-value="departmentFilterText"
                   :preselected-value="departmentId"
                   class="ent-settings__filter"
                   @csvaluechanged="changeDepartment" />
        <button v-if="canChange"
                class="sk-btn sk-btn--default ent-settings__add-btn"
                @click="openAddModal">{{ $gettext('New department') }}</button>
      </div>
      <department v-for="item in departmentsList"
                  :key="item.id"
                  :department="item"
                  @deleteitem="openRemoveModal"
                  @edititem="editItem" />
      <section v-if="pages"
               class="ent-settings__pagination-cont">
        <items-amount class="ent-settings__items-per-page"
                      @amountchanged="refetchData" />
        <sk-pagination v-if="pages && pages > 1 && !departmentId"
                       :current="page"
                       :total="pages"
                       @prev="toPrevPage"
                       @next="toNextPage"
                       @newpage="toNewPage" />
      </section>
    </div>
  </page-data-section>
</template>)

<script>
  import {mapGetters, mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';
  import DepartAddrSettingPrototype from '@/prototypes/DepartAddrSettingPrototype';
  import Department from '@/components/enterprise/departments/Department';
  import ItemsAmount from '@/components/shared_components/ItemsAmount';

  export default {
    asyncData({store, route}) {
      let promisesArr = [];
      const options = {
        items: route.query,
        page: route.query.page,
        verbose: true
      };
      // fetch few paginated verbose departments for department table
      promisesArr = [...promisesArr, store.dispatch('EnterpriseStore/getDepartments', options)];
      // fetch all departments which are also non verbose for department filter select
      if (!store.state.EnterpriseStore.allDepartments) {
        promisesArr = [...promisesArr, store.dispatch('EnterpriseStore/getAllDepartments')];
      }
      if (!store.state.EnterpriseStore.addresses) {
        promisesArr = [...promisesArr, store.dispatch('EnterpriseStore/getAddresses')];
      }
      if (!store.state.EnterpriseStore.employees) {
        promisesArr = [...promisesArr, store.dispatch('EnterpriseStore/getEmployees')];
      }
      if (!store.state.PaymentPayoutStore.paymentMethods) {
        promisesArr = [...promisesArr, store.dispatch('PaymentPayoutStore/getPayments')];
      }

      return Promise.all(promisesArr);
    },
    components: {
      department: Department,
      'items-amount': ItemsAmount,
    },
    extends: DepartAddrSettingPrototype,
    data() {
      return {
        currentPage: this.$route.query.page || 1,
      };
    },
    computed: {
      ...mapGetters('PaymentPayoutStore', [
        'defaultPaymentMethod'
      ]),
      ...mapState('PaymentPayoutStore', {
        paymentMethods: ({paymentMethods}) => paymentMethods || []
      }),
      ...mapState('EnterpriseStore', {
        filteredDepartments: ({filteredDepartments}) => filteredDepartments || [],
        pages: ({departmentsPages}) => departmentsPages || 1,
        page: ({departmentsPage}) => departmentsPage || 1,
      }),
      departmentsList() {
        const departments = this.departmentId ? this.filteredDepartments : this.departments;

        return departments.map((department) => {
          const departmentPaymentMethod = department.paymentMethod?.archived ? '' : department.paymentMethod;
          const departmentPaymentMethodName = helpers.getJobInfo.getPaymentMethodName(this, {
            paymentMethodData: departmentPaymentMethod || {}
          });
          const defaultPaymentMethodNote = this.$gettext('<span class="ent-settings__light-text">(default for enterprise)</span>');
          const defaultPaymentMethodName = this.defaultPaymentMethod
            ? `${helpers.getJobInfo.getPaymentMethodName(this, {
              paymentMethodData: this.defaultPaymentMethod || {}
            })} ${defaultPaymentMethodNote}`
            : '';
          return {
            ...department,
            ...{
              paymentMethodName: departmentPaymentMethodName || defaultPaymentMethodName || ''
            }
          };
        });
      },
      sectionTitle() {
        return this.$gettext('Departments');
      },
      pageParams() { return {page: this.currentPage}; }
    },
    methods: {
      ...mapMutations('EnterpriseStore', ['removeDepartments', 'clearPaging']),
      openAddModal(params) {
        this.fetchModalData(() => {
          this.$store.commit('ModalStore/setModal', {
            component: 'add-department',
            width: 410,
            data: {
              ...params,
              title: this.$gettext('New department'),
              context: this,
              successAddingMemberCallbackName: 'preselectDataAfterAddingMember',
              successAddingPaymentCallbackName: 'preselectDataAfterAddingPayment',
              successAddingAddressCallbackName: 'preselectDataAfterAddingAddress'
            }
          });
        });
      },
      editItem(currentDepartment) {
        this.openEditModal({currentDepartment});
      },
      openEditModal(params) {
        this.fetchModalData(() => {
          this.$store.commit('ModalStore/setModal', {
            component: 'add-department',
            width: 410,
            data: {
              ...params,
              title: this.$gettext('Edit department'),
              context: this,
              successAddingMemberCallbackName: 'preselectDataAfterAddingMember',
              successAddingPaymentCallbackName: 'preselectDataAfterAddingPayment',
              successAddingAddressCallbackName: 'preselectDataAfterAddingAddress',
              successHandlingCallback: this.departmentId ? 'getDepartment' : 'getAllDepartments'
            }
          });
        });
      },
      getDepartment() {
        this.$store.dispatch('EnterpriseStore/getDepartment', this.departmentId);
        this.$store.dispatch('EnterpriseStore/getAllDepartments');
      },
      getAllDepartments() {
        this.$store.dispatch('EnterpriseStore/getAllDepartments');
      },
      reopenModal(params) {
        const {currentDepartment} = params;

        currentDepartment?.id ? this.openEditModal(params) : this.openAddModal(params);
      },
      fetchModalData(callback) {
        this.progressActive = true;
        this.$options.asyncData({
          store: this.$store,
          route: this.$route
        }).then(() => {
          this.progressActive = false;
          callback();
        });
      },
      preselectDataAfterAddingMember(params) {
        this.$store.commit('EnterpriseStore/setEmployees', '');
        this.reopenModal(params);
      },
      preselectDataAfterAddingPayment(params) {
        this.$store.commit('PaymentPayoutStore/savePaymentMethods', '');
        this.reopenModal(params);
      },
      preselectDataAfterAddingAddress(params) {
        this.$store.commit('EnterpriseStore/setDepartments', '');
        this.reopenModal(params);
      },
      openRemoveModal(departmentId) {
        if (this.canChange) {
          this.$store.commit('ModalStore/setModal', {
            component: 'remove-confirm',
            classes: ['remove-confirm'],
            width: 410,
            data: {
              title: this.$gettext('Delete department?'),
              text: this.$gettext('Are you sure you want to delete this department from your business account?'),
              btnTexts: {
                removeBtnText: this.$gettext('Delete'),
                cancelBtnText: this.$gettext('Cancel')
              },
              id: departmentId,
              context: this
            }
          });
        }
      },
      removeItem(id) {
        this.progressActive = true;
        this.$store.dispatch('EnterpriseStore/removeDepartment', id).then(() => {
          this.$skGA.emitEvent(this.$GADataConstructor.getEnterpriseObjGA('department', 'remove'));
          this.departmentId = '';
          this.$store.commit('removeSelectsData', 'departments');
          this.progressActive = false;
        }).catch((error) => {
          this.progressActive = false;
          if (error?.status == 401) {
            Promise.all([this.$store.dispatch('UserInfoStore/fetchUserInfo', this.userUid)]).then(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettext('Sorry, you don\'t have rights to manage departments. Please contact an administrator.')
              });
            });
          } else if (error.data.errors?.members_have_other_department) {
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.$gettext('You can\'t delete this department. Some members have this department as the only one they\'re in. In order to delete this department, please add or move those members to other department(s).')
            });
          } else if (error.data.errors?.other_departments_exist) {
            this.$store.commit('InfoModalStore/setInfoModal', {
              title: this.$gettext('Delete the department'),
              text: this.$gettext('Sorry, you can\'t delete the last department. Please create at least one other department in order to delete this one.')
            });
          } else {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }
        });
      },
      filterData() {
        this.progressActive = true;
        this.$store.dispatch('EnterpriseStore/getDepartment', this.departmentId).then(() => {
          this.progressActive = false;
        }).catch(() => {
          this.progressActive = false;
        });
      },
      refetchData() {
        this.removeDepartments();
        this.$store.commit('GlobalStore/startProgress');
        this.$options.asyncData({
          store: this.$store,
          route: this.$route
        }).then(() => {
          this.$store.commit('GlobalStore/stopProgress');
        });
      },
      submitPaginationFilters(params) {
        this.removeDepartments();
        this.$router.push(this.$makeRoute({name: 'BuyerDepartments', query: params}));
      },
      toPrevPage() {
        this.currentPage -= 1;
        this.submitPaginationFilters(this.pageParams);
      },
      toNewPage(page) {
        this.currentPage = +page;
        this.submitPaginationFilters(this.pageParams);
      },
      toNextPage() {
        this.currentPage += 1;
        this.submitPaginationFilters(this.pageParams);
      },
    },
    beforeMount() {
      if (!this.$route.query.page) {
        this.$router.replace(this.$makeRoute({
          name: 'BuyerDepartments',
          query: {
            page: this.currentPage
          }
        }));
      }
    },
    beforeDestroy() {
      this.clearPaging();
    }
  };
</script>

<style>
  .ent-settings__light-text {
    color: #a7abbd;
    font-size: 10px;
  }

  .ent-settings-table__col--memberCount,
  .ent-settings-table-row__col--memberCount {
    max-width: 65px;
  }

  .ent-settings__pagination-cont {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    padding: 5px 10px;
  }

  .ent-settings__items-per-page {
    margin-right: auto;
  }
</style>

<style scoped src="@assets/css/enterprise_settings_page.css"></style>
